import { render, staticRenderFns } from "./MsgList.vue?vue&type=template&id=54b37687&"
import script from "./MsgList.vue?vue&type=script&lang=js&"
export * from "./MsgList.vue?vue&type=script&lang=js&"
import style0 from "./MsgList.vue?vue&type=style&index=0&lang=stylus&rel=stylesheet%2Fstylus&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports