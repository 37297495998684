
let OSS = require('ali-oss');

let client = new OSS({
  accessKeyId: 'LTAI4Fjjayr6koUUyBEuhkNm',   // 你创建的Bucket时获取的
  accessKeySecret: '8LXANfhKfzDrWjq6bYL3xlKEIOPOl6',  // 你创建的Bucket时获取的
  bucket: 'fzyuan',  // 你创建的Bucket名称
  region: 'oss-cn-hangzhou'   //  你所购买oss服务的区域，默认oss-cn-hangzhou
});
// 上传
export async function put (filePath, file) {
  try {
    let result = await client.put(filePath, file)
    return result
  } catch (err) {
    console.log(err)
  }
}

// 删除
export async function remove (filePath) {
  try {
    let result = await client.delete(filePath)
    return result
  } catch (err) {
    console.log(err)
  }
}


/**
 * 生成随机文件名称
 * 规则八位随机字符，加下划线连接时间戳
 */
export const getFileNameUUID = () => {
  function rx() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return `${+new Date()}_${rx()}${rx()}`
}


export default {
  fileHost:'https://img.cxzai.com/',
  put,
  remove,
  getFileNameUUID
}
