<template>
  <div id="app">
    <div class="top-header">
        <el-row :span="24" class="header-content">
          <el-col :span="5" :class="'logo-width'" class="logo">
            <router-link to="/">
              <el-image class="logo"  fit="contain" :src="require('./assets/logo.png')"></el-image>
            </router-link>

          </el-col>
          <el-col :span="15" class="breadcrumb-container">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-nav"
              mode="horizontal"
              @select="handleSelect"
              text-color="#eeeeee"
              background-color="rgba(0,0,0,0)"
              active-text-color="#ffffff">
              <el-menu-item index="1">首页</el-menu-item>
              <el-menu-item index="2">找缘分</el-menu-item>
<!--              <el-menu-item index="3">活动</el-menu-item>-->
<!--              <el-menu-item index="4"> <a href="https://www.ele.me" target="_blank">晒幸福</a></el-menu-item>-->
              <el-menu-item index="5">会员中心</el-menu-item>
            </el-menu>
          </el-col>
          <el-col v-if="token" :span="4" class="userinfo">
            <el-dropdown trigger="hover">
              <i class="el-icon-user"  style="font-size: 26px; color: white;"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="onJumpMineCenter">个人中心</el-dropdown-item>
                <el-dropdown-item @click.native="onJumpMsgCenter">我的消息</el-dropdown-item>
                <el-dropdown-item @click.native="onJumpSetting">系统设置</el-dropdown-item>
                <el-dropdown-item divided @click.native="logoutFun">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
          <el-col v-else :span="4" class="userinfo">
            <el-button v-if="routeName!=='Register'"  class="btn-top-opt" round @click="onClickRegister">免费注册</el-button>
            <el-button v-if="routeName!=='Index'" class="btn-top-opt" round @click="onClickLogin">登录</el-button>
          </el-col>
        </el-row>


    </div>
    <router-view :key="$route.fullPath"/>
    <div class="footer-view">
      <el-row>客服热线：18516679065（周一至周日：9:00-21:00） <span class="margin-left">客服信箱：cxzkj2018@163.com</span></el-row>
      <el-row>违法和不良信息举报：17805608830       <span class="margin-left">举报信箱：cxzkj2020@163.com</span></el-row>
      <el-row type="flex" class="divider-row">公司地址：安徽省合肥市蜀山区华林家园27栋2007           <a style="margin-left: 50px"  href="/article/19" target="_blank">服务协议</a><div class="ver-divider"></div><a  href="/article/16" target="_blank">隐私条款</a></el-row>
      <el-row style="margin-top: 20px">备案号： <a  href="https://beian.miit.gov.cn/" target="_blank">皖ICP备17011993号-1</a> </el-row>
      <el-row>  Copyright © 2020 版权所有：安徽趁现在科技有限公司</el-row>
    </div>


  </div>
</template>
<script>
import { setStore, getStore } from './utils/storage'
import constant from './constant'

export default {
  data () {
    return {
      token:'',
      routeName:'',
      activeIndex: '1',
      activeIndex2: '1'
    }
  },


  created () {
    this.token = getStore(constant.K_TOKEN)
    this.routeName = this.$route.name
    if (this.routeName=='Index'){
      this.activeIndex = '1'
    }else if (this.routeName=='FindFate'){
      this.activeIndex = '2'
    }else if (this.routeName=='Activity'){
      this.activeIndex = '3'
    }else if (this.routeName=='VipCenter'){
      this.activeIndex = '4'
    }
  },

  updated () {
    this.token = getStore(constant.K_TOKEN)
    this.routeName = this.$route.name
    if (this.routeName=='Index'){
      this.activeIndex = '1'
    }else if (this.routeName=='FindFate'){
      this.activeIndex = '2'
    }else if (this.routeName=='Activity'){
      this.activeIndex = '3'
    }else if (this.routeName=='VipCenter'){
      this.activeIndex = '4'
    }
  },

  methods: {
    handleSelect (key, keyPath) {
      // console.log(key)
      this.$router.push(key==2?"/find_fate":key==3?"/activity":key==4?"":key==5?"/vip":"/");
    },
    logoutFun () {
      this.token = ''
      setStore(constant.K_TOKEN, '')
      this.$router.go(0);

    },
    onJumpMineCenter(){
      this.$router.push("/mine/base_info");
    },
    onJumpMsgCenter(){
      this.$router.push("/msg");
    },
    onJumpSetting(){
      this.$router.push("/mine/modfiy_pwd");
    },
    onClickRegister(){
      this.$router.push("/register");
    },
    onClickLogin(){
      this.$router.push("/");
    },

  }
}
</script>

<style lang="stylus" rel="stylesheet/stylus">
@import "./common/stylus/mixin.styl"
#app {
  display flex
  flex-direction column
  align-items center
  background #F6F6F6 !important
}

.top-header {
  position fixed
  top 0
  left 0
  z-index 9999
  width 100%;
  height 64px;
  display flex;
  flex-direction row;
  align-items center;
  justify-content center;
  background-color #FF4666;
}

.top-header .header-content{
  width: 1200px
  display flex;
  flex-direction row;
  align-items center;
}

.logo{
  width 180px;
  height 48px;
}

.el-menu-nav{

}

.userinfo{
  display flex
  flex-direction row
  align-items center
  justify-content flex-end
}

.footer-view
  width 100%
  background-color white
  margin-top 60px
  padding 20px 0
  box-sizing border-box
  display flex
  flex-direction column
  align-items center
  color #454545
  font-size 15px
  line-height 30px
  .margin-left
    margin-left 30px
  .divider-row
    align-items center
  .ver-divider
    height 12px
    width 1px
    background-color #aaaaaa
    margin 0 10px
  a
    color #454545
  a:hover
    color #ff4666

.btn-top-opt{
  height 34px !important
  line-height 0 !important
  background-color rgba(255,255,255,0.9) !important
  color #666666 !important
}

</style>
